import { memo, FC, useState, useEffect, useCallback } from 'react';
import { SettingsFlow } from '@ory/client';

import {
  ButtonStyle,
  ButtonType,
  Client,
  DividerType,
  FlowStrategy,
  InputType,
  MessageType,
} from 'enum';
import { getMessages, onFilterFlowNodes } from 'helpers';
import {
  CardSettings,
  Button,
  Divider,
  Hints,
  Flow,
  Message,
} from 'components';
import { CardSetting, NodeFlow } from 'interfaces';
import { Maps } from '../../../../constants';

import styles from './UserAccess.module.scss';

interface AccessSettingsProps {
  activeStrategy: FlowStrategy;
  flow: SettingsFlow;
  displayPassword: boolean;
  highlightLookup: boolean;
  setDisplayPassword: (value: boolean) => void;
  onSubmit: (
    values: { [key: string]: string },
    flowStrategy?: FlowStrategy,
  ) => void;
}

export const CARD_SETTINGS: CardSetting[] = [
  {
    wrapperClassname: '',
    title: 'Change Password',
    icon: 'icon-dgpass-password',
    text: 'Password requirements:',
    name: 'access-password',
    listHints: [
      'At least 8 characters',
      'All characters allowed',
      'Your password cannot be similar to your user ID',
      'Passwords previously exposed in data breaches are not allowed',
    ],
    flowStrategy: FlowStrategy.Password,
  },
  {
    wrapperClassname: 'mt-3',
    title: 'Manage Hardware Tokens',
    name: 'access-webauth',
    icon: 'icon-dgpass-hardware-tokens',
    flowStrategy: FlowStrategy.Webauthn,
  },
  {
    wrapperClassname: 'mt-3',
    title: 'Manage Passkey',
    name: 'access-passkey',
    icon: 'icon-dgpass-hardware-tokens',
    text: 'Passkeys are an easier and more secure alternative to passwords. They let you sign-in with just your fingerprint, face scan, or screen lock.',
    flowStrategy: FlowStrategy.Passkey,
  },
  {
    wrapperClassname: '',
    title: 'Manage MFA methods',
    name: 'access-mfa',
    icon: 'icon-dgpass-mfa',
    text: 'By enabling multi-factor authentication, you introduce an additional verification step that can protect user login or self-service actions, such as updating account information or credentials, from malicious actors.',
    flowStrategy: FlowStrategy.Mfa,
  },  
  {
    wrapperClassname: 'mt-3',
    title: 'Manage MFA Backup Recovery Codes',
    icon: 'icon-dgpass-recovery-codes',
    name: 'access-lookup',
    text: 'Backup recovery codes are pre-generated codes  which can be used if you lose access to your TOTP or WebAuthn devices. The codes are valid only once and should be regenerated from time to time.',
    flowStrategy: FlowStrategy.LookupSecret,
  },
  {
    wrapperClassname: 'mt-3',
    title: 'Manage MFA Authenticator',
    name: 'access-totp',
    icon: 'icon-dgpass-mobile',
    flowStrategy: FlowStrategy.Totp,
  },
];

export const UserAccess: FC<AccessSettingsProps> = memo(
  ({
    flow,
    activeStrategy,
    displayPassword,
    highlightLookup,
    setDisplayPassword,
    onSubmit,
  }) => {
    const [highlight, setHighlight] = useState<boolean>(false);

    const setHighlightFalse = useCallback(() => {
      setHighlight(false);
    }, []);

    const onFilterFlowByFlowStrategy = (flowStrategy: FlowStrategy) => {
      if (flowStrategy === FlowStrategy.Mfa) {
        const flowState = onFilterFlowNodes(flow, flowStrategy);
        const flowNodes = flowState.ui.nodes.map(
          (node: NodeFlow, index: number) => {
            return {
              ...node,
              ...node.attributes,
              priority:
                node?.attributes?.type === InputType.CHECKBOX
                  ? Maps.MFA_PRIORITY_MAP.get(node?.attributes?.name as Client)
                  : index + 100,
            };
          },
        );
        return {
          ...flowState,
          ui: {
            ...flowState.ui,
            nodes: flowNodes.sort(
              (a: NodeFlow, b: NodeFlow) => a.priority! - b.priority!,
            ),
          },
        };
      }

      return onFilterFlowNodes(flow, flowStrategy);
    };

    useEffect(() => {
      setHighlight(highlightLookup);
    }, [highlightLookup]);

    const onRenderFlow = (
      flowState: SettingsFlow,
      card: CardSetting,
      hasPassword = true,
    ) => {
      {
        return (
          flowState &&
          hasPassword && (
            <Flow
              flowStrategy={card.flowStrategy}
              activeStrategy={activeStrategy}
              showMessages={card.flowStrategy !== FlowStrategy.Password}
              onSubmit={onSubmit}
              flow={onFilterFlowByFlowStrategy(card.flowStrategy)}
            />
          )
        );
      }
    };

    const onRenderCard = (card: CardSetting) => {
      const message = getMessages(flow?.ui.messages || []);
      let hasLookup = false;

      if (card.flowStrategy === FlowStrategy.LookupSecret) {
        const flowLookup = onFilterFlowByFlowStrategy(card.flowStrategy);
        hasLookup = flowLookup.ui.nodes?.length == 2;
      }

      return (
        <CardSettings
          icon={card.icon}
          key={card.title}
          wrapperClassname={`${
            highlight
              ? `${
                  card.flowStrategy === FlowStrategy.LookupSecret
                    ? 'highlight-box overflow '
                    : 'dim '
                }`
              : ''
          }${card.wrapperClassname}`}
          title={card.title}
          name={card.name}
        >
          {highlight && card.flowStrategy === FlowStrategy.LookupSecret && (
            <div className="message text-center">
              When setting up multi-factor authentication, be sure to enable
              lookup codes.
              <br />
              These codes will help you restore access to your account if other
              login methods don`t work.
            </div>
          )}

          {card.flowStrategy === FlowStrategy.Password &&
            activeStrategy === card.flowStrategy &&
            message &&
            !displayPassword && (
              <div className="py-3">
                <Message type={MessageType.Text} message={message} />
              </div>
            )}

          {((card.text && card.flowStrategy !== FlowStrategy.LookupSecret) ||
            hasLookup) && <p className="py-2">{card.text}</p>}

          {card.listHints && (
            <>
              <Hints list={card.listHints} />
              <Divider type={DividerType.Line} className="my-3" />
            </>
          )}

          {card.flowStrategy === FlowStrategy.Password
            ? onRenderFlow(flow, card, displayPassword)
            : onRenderFlow(flow, card)}

          {!displayPassword && card.flowStrategy === FlowStrategy.Password && (
            <Button
              name="Change password"
              className={`${ButtonStyle.OUTLINEPRIMARY} float-end mt-3`}
              type={ButtonType.BUTTON}
              onClick={() => setDisplayPassword(true)}
            >
              Change Password
            </Button>
          )}
        </CardSettings>
      );
    };

    const onFilterCardSettings = (flowStrategies: FlowStrategy[]) => {
      return CARD_SETTINGS.filter((card) =>
        flowStrategies.includes(card.flowStrategy),
      );
    };

    return (
      <div className="col-12 user-access-settings">
        <div
          className={`${highlight ? 'modal-backdrop2' : ''}`}
          onClick={setHighlightFalse}
        ></div>
        <div className="row">
          <div className={`${styles.column} col`}>
            {onFilterCardSettings([
              FlowStrategy.Password,
              FlowStrategy.Webauthn,
              FlowStrategy.Passkey,
            ]).map((card) => onRenderCard(card))}
          </div>
          <div className={`${styles.column} col`}>
            {onFilterCardSettings([
              FlowStrategy.Mfa, 
              FlowStrategy.Totp, 
              FlowStrategy.LookupSecret
            ]).map((card) => onRenderCard(card))}
          </div>
        </div>
      </div>
    );
  },
);
