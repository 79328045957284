import {
  FC,
  BaseSyntheticEvent,
  FormEventHandler,
  memo,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  LoginFlow,
  RegistrationFlow,
  SettingsFlow,
  VerificationFlow,
} from '@ory/client';
import { useLocation } from 'react-router';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Error,
  Image,
  Input,
  Message,
  Radio,
  SelectInput,
  Switch,
  Tabs,
  Text,
  WebauthScript,
  GroupButtons,
  VerificationEmail,
} from 'components';

import PhoneInput, { Value as PhoneNumberType } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';

import {
  ButtonStyle,
  ButtonType,
  Client,
  DividerText,
  DividerType,
  FlowStrategy,
  IconType,
  InputType,
  MessageType,
  NodeType,
} from 'enum';
import {
  getCountries,
  getErrors,
  getMessages,
  updateMessageText,
  removeEmpty,
  removeObjectValueByKeys,
  resetRadioInputs,
} from 'helpers';
import { Message as MessageText, Maps, ConstantsTest } from '../../constants/';
import { BaseFlow, ErrorGlobal, NodeFlow } from 'interfaces';
import { routes } from '../../models/routes';
import { capitalizeFirstLetters } from 'utils';

interface FlowProps {
  flow:
    | LoginFlow
    | RegistrationFlow
    | VerificationFlow
    | SettingsFlow
    | BaseFlow;
  error?: ErrorGlobal;
  flowStrategy: FlowStrategy;
  loginChallenge?: string;
  activeStrategy?: FlowStrategy;
  showMessages?: boolean;
  hideFlow?: boolean;
  onChangeTab?: (event: BaseSyntheticEvent) => void;
  onSubmit?: (
    values: { [key: string]: string },
    flowStrategy?: FlowStrategy,
  ) => void;
}

const createOptions = (defaultOption: string, options: string[]) => {
  const selectOptions = options.map((option: string) => {
    return {
      name: option,
      value: option,
    };
  });
  return [
    {
      name: defaultOption,
      value: '',
    },
    ...selectOptions,
  ];
};

export const Flow: FC<FlowProps> = memo(
  ({
    flow,
    flowStrategy,
    activeStrategy,
    error,
    loginChallenge,
    showMessages = true,
    hideFlow,
    onChangeTab,
    onSubmit,
  }: FlowProps) => {
    const [formState, setFormState] = useState<{
      values: { [key: string]: string };
    }>({
      values: {},
    });

    const location = useLocation();

    const message = useMemo(() => {
      return updateMessageText(getMessages(flow?.ui?.messages || []));
    }, [flow]);

    const onFilterValues = (
      values: { [key: string]: string },
      keyValue: string,
    ) => {
      const found =
        Client.LookupSecretDisable in values &&
        (Client.LookupSecretReveal in values ||
          Client.LookupSecretRegenerate in values);
      if (found) {
        Object.keys(values).forEach((key: string) => {
          if (key !== Client.CsrfToken && key !== keyValue) {
            delete values[key];
          }
        });
      }
      return values;
    };

    const onSubmitHandler = (
      e: BaseSyntheticEvent & { nativeEvent: { submitter: { name: string } } },
    ) => {
      let { values } = formState;
      if (
        !loginChallenge &&
        ![
          FlowStrategy.SignIn,
          FlowStrategy.Recovery,
          FlowStrategy.SignUp,
          FlowStrategy.FirstVerification,
        ].includes(flowStrategy)
      ) {
        if (flowStrategy === FlowStrategy.LookupSecret) {
          values = onFilterValues(values, e?.nativeEvent?.submitter?.name);
        }

        if (
          flowStrategy === FlowStrategy.Totp &&
          location.pathname === routes.accessSettings
        ) {
          values = { ...values, [Client.Authenticator]: true + '' };
        }

        if (
          flowStrategy === FlowStrategy.Password &&
          location.pathname === routes.accessSettings &&
          e?.nativeEvent?.submitter?.name === Client.Method
        ) {
          values = removeObjectValueByKeys(values, [Client.PasswordRemove]);
        }

        if (e?.nativeEvent?.submitter?.name === Client.ResetOtp) {
          values = { ...values, [Client.SendOtp]: 'true' };
          values[Client.OtpCode] && delete values[Client.OtpCode];
        }

        values = { ...removeEmpty(values) };
        onSubmit?.(values, flowStrategy);
        if (flowStrategy != FlowStrategy.SignInQRCode) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };

    const onChangeHandler = (e: BaseSyntheticEvent, inputType: InputType) => {
      setFormState((currentState) => {
        const values = Object.assign(
          { ...currentState.values },
          inputType === InputType.RADIO && resetRadioInputs(flow.ui?.nodes),
          {
            [e.target.name]: [InputType.RADIO, InputType.CHECKBOX].includes(
              inputType,
            )
              ? +e.target.checked
              : e.target.value,
          },
        );

        if (e.target.name.startsWith('transient')) {
          const fieldName = e.target.name.substring(10);
          values[
            'transient_payload'
          ] = `{"${fieldName}" : "${e.target.value}"}`;
        }

        if (
          (inputType === InputType.CHECKBOX &&
            flowStrategy === FlowStrategy.Mfa) ||
          (location.pathname === routes.notifications &&
            e.target.name === Client.Enable)
        ) {
          onSubmit?.(values, flowStrategy);
        }

        return { ...currentState, values };
      });
    };

    const onChangeOtpCode = useCallback((otpCode: string) => {
      setFormState((currentState) => ({
        ...currentState,
        values: {
          ...currentState.values,
          [Client.OtpCode]: otpCode,
        },
      }));
    }, []);

    const setPhoneNumber = (value: string, name: string) => {
      setFormState((currentState) => {
        return {
          ...currentState,
          values: {
            ...currentState.values,
            [name]: value,
          },
        };
      });
    };

    const onClickWebauth = (e: BaseSyntheticEvent, onclick: string) => {
      if (e.target.name == Client.WebAuthRegisterTrigger) {
        const keyNameInput = document.getElementById(
          Client.WebAuthRegisterDisplayName,
        ) as HTMLInputElement;
        if (keyNameInput?.value == '') {
          alert('Please fill out the name of the security key');
          keyNameInput.focus();
          return;
        }
      }

      e.stopPropagation();
      e.preventDefault();
      const run = new Function(onclick);
      run();
      return;
    };

    const getButtonStyleByStrategy = useMemo(() => {
      return [
        FlowStrategy.SignInSecurityKey,
        FlowStrategy.SignUpSecurityKey,
      ].includes(flowStrategy)
        ? ButtonStyle.OUTLINEPRIMARY
        : ButtonStyle.PRIMARY;
    }, [flowStrategy]);

    const getIconNameByStrategy = (
      strategy: FlowStrategy,
      iconName: IconType,
    ) => {
      return [
        FlowStrategy.SignInSecurityKey,
        FlowStrategy.SignUpSecurityKey,
      ].includes(strategy)
        ? Maps.INPUT_ICON_MAP.get(IconType.Key)
        : Maps.INPUT_ICON_MAP.get(iconName);
    };

    const getTooltipByStrategy = (
      strategy: FlowStrategy,
      inputName: string,
    ) => {
      return (
        ([
          FlowStrategy.SignInSecurityKey,
          FlowStrategy.SignUpSecurityKey,
          FlowStrategy.SignUp,
          FlowStrategy.SignIn,
        ].includes(strategy) &&
          (inputName === IconType.Identifier ||
            inputName === IconType.NationalId ||
            inputName === IconType.WebAuthDisplayName)) ||
        (FlowStrategy.SignUp === strategy && inputName === IconType.Password)
      );
    };

    const getWrapperClassesByStrategy = useMemo(() => {
      return flowStrategy === FlowStrategy.Settings
        ? 'd-inline-block col-12 col-lg-6 py-1 px-2'
        : '';
    }, [flowStrategy]);

    const renderFlow = useCallback(
      (
        flowNodes:
          | LoginFlow
          | RegistrationFlow
          | VerificationFlow
          | SettingsFlow
          | BaseFlow,
      ) => {
        const { values } = formState;
        return flowNodes?.ui?.nodes.map((node: Record<string, any>) => {
          const {
            disabled,
            name,
            required,
            type,
            options,
            value,
            title,
            href,
            crossorigin,
            id,
            classname,
            iconclassname,
            iconstyle,
            integrity,
            nonce,
            referrerpolicy,
            src,
            width,
            height,
            hidden,
            onclick,
          } = node.attributes;
          const { tabs } = node;
          const secret = node.attributes?.text?.text;
          const { text } = node.meta.label || { text: name };
          if (node.attributes.type === InputType.DATE) {
            return (
              <DatePicker
                error={getErrors(node.messages)}
                wrapperClassname={getWrapperClassesByStrategy}
                onChange={(e) => onChangeHandler(e, type)}
                value={values ? values[name] : ''}
                className="form-control"
                label={text}
                key={name}
                disabled={disabled}
                name={name}
                type={type}
                required={required}
              />
            );
          } else if (node.attributes.type === InputType.TELEPHONE) {
            const errorMessage = getErrors(node.messages);
            return (
              <div
                key={name}
                className={`mb-2 ${node.attributes.hidden ? 'd-none' : ''}`}
              >
                <div className={`focus-label ${errorMessage ? 'error' : ''}`}>
                  <label
                    htmlFor={name}
                    className={`form-check-label form-label ${
                      required ? 'required' : ''
                    }`}
                  >
                    {text}
                  </label>
                  <PhoneInput
                    international
                    flags={flags}
                    key={name}
                    name={name}
                    required={required}
                    disabled={hidden || disabled}
                    className="d-flex form-control"
                    value={values[name]}
                    onChange={(phoneValue) =>
                      setPhoneNumber(phoneValue as PhoneNumberType, name)
                    }
                  />
                  {errorMessage && <Error message={errorMessage} />}
                </div>
              </div>
            );
          } else if (
            node.attributes.type === InputType.CHECKBOX &&
            !location.pathname.includes(routes.settings)
          ) {
            return (
              <Checkbox
                onChange={(e) => onChangeHandler(e, type)}
                type={type}
                key={name}
                name={name}
                label={text}
                value={values ? values[name] : ''}
                checked={!!values[name]}
                hidden={hidden}
              />
            );
          } else if (
            node.attributes.type === InputType.RADIO &&
            !location.pathname.includes(routes.settings)
          ) {
            return (
              <Radio
                onChange={(e) => onChangeHandler(e, type)}
                type={type}
                key={name}
                name={name}
                label={text}
                value={values[name]}
                checked={!!values[name]}
              />
            );
          } else if (
            node.attributes.type === InputType.CHECKBOX &&
            location.pathname.includes(routes.settings)
          ) {
            return (
              <Switch
                cardClass={`${
                  ![
                    FlowStrategy.NotificationPreferencesEmail,
                    FlowStrategy.NotificationPreferencesPush,
                    FlowStrategy.NotificationPreferencesSms,
                  ].includes(flowStrategy)
                    ? ''
                    : 'px-0'
                }`}
                onChange={(e) => onChangeHandler(e, type)}
                error={getErrors(node.messages)}
                type={type}
                key={name}
                withDivider={name === Client.Authenticator}
                tooltip={name === Client.Authenticator}
                tooltipMessage={MessageText.TOOLTIP_MFA_MESSAGE}
                name={name}
                disabled={disabled}
                label={text}
                labelClass={`${
                  ![
                    FlowStrategy.NotificationPreferencesEmail,
                    FlowStrategy.NotificationPreferencesPush,
                    FlowStrategy.NotificationPreferencesSms,
                  ].includes(flowStrategy)
                    ? 'ms-2'
                    : ''
                }`}
                value={values ? values[name] : ''}
                checked={values[name] ? !!values[name] : node.attributes.value}
                className={
                  [
                    FlowStrategy.NotificationPreferencesEmail,
                    FlowStrategy.NotificationPreferencesPush,
                    FlowStrategy.NotificationPreferencesSms,
                  ].includes(flowStrategy)
                    ? 'float-end'
                    : ''
                }
              />
            );
          } else if (node.attributes.type === InputType.SCRIPT) {
            return (
              <WebauthScript
                key={id}
                type={type}
                async={false}
                crossorigin={crossorigin}
                id={id}
                integrity={integrity}
                nonce={nonce}
                referrerpolicy={referrerpolicy}
                src={src}
              />
            );
          } else if (node.type === NodeType.A) {
            return (
              <Button
                key={title.text}
                onClick={() => window.location.replace(href)}
                name={name}
                className={`${ButtonStyle.PRIMARY} my-3 w-100`}
                type={ButtonType.BUTTON}
              >
                {capitalizeFirstLetters(title.text)}
              </Button>
            );
          } else if (text === 'Country') {
            const selectOptions = createOptions(text, getCountries());
            return (
              <SelectInput
                wrapperClassname={getWrapperClassesByStrategy}
                error={getErrors(node.messages)}
                onChange={(e) => onChangeHandler(e, type)}
                selectedValue={values ? values[name] : ''}
                key={name}
                name={name}
                label={text}
                type={InputType.SELECT}
                disabled={disabled}
                required={required}
                options={selectOptions}
              />
            );
          } else if (
            node.attributes.options &&
            node.attributes.options.length
          ) {
            const selectOptions = createOptions(text, options);
            return (
              <SelectInput
                wrapperClassname={getWrapperClassesByStrategy}
                error={getErrors(node.messages)}
                onChange={(e) => onChangeHandler(e, type)}
                selectedValue={values ? values[name] : ''}
                key={name}
                name={name}
                label={text}
                type={InputType.SELECT}
                disabled={disabled}
                required={required}
                options={selectOptions}
              />
            );
          } else if (node.attributes.type === InputType.BUTTON) {
            return (
              <Button
                key={name}
                onClick={(e) => onClickWebauth(e, onclick)}
                name={name}
                className={`${getButtonStyleByStrategy} ${
                  location.pathname.includes(routes.settings)
                    ? 'float-end mt-3'
                    : 'my-3 w-100'
                }`}
                type={type}
              >
                {capitalizeFirstLetters(text)}
              </Button>
            );
          } else if (
            node.attributes.type === InputType.IMG ||
            node.type === InputType.IMG
          ) {
            return (
              <Image
                key={id}
                id={id}
                src={src}
                width={width}
                height={height}
                text={text}
                hidden={hidden}
              />
            );
          } else if (node.type === InputType.TEXT) {
            return (
              <Text
                key={id}
                name={name}
                id={id}
                text={text}
                code={secret}
                onChange={(e) => onChangeHandler(e, type)}
                flowStrategy={flowStrategy}
                label={name}
                hidden={hidden}
              />
            );
          } else if (node.type === InputType.DIVIDER) {
            return (
              <Divider
                key={name}
                type={DividerType.Text}
                text={
                  flowStrategy === FlowStrategy.SignIn
                    ? DividerText.Or
                    : DividerText.And
                }
                className="my-3"
              />
            );
          } else if (node.type === InputType.TABS) {
            return (
              <Tabs
                key={name}
                tabs={tabs}
                onChange={
                  onChangeTab
                    ? (e: BaseSyntheticEvent) => onChangeTab(e)
                    : undefined
                }
              />
            );
          } else if (node.attributes.type === InputType.SUBMIT) {
            return (
              <Button
                wrapperClassname={
                  flowStrategy === FlowStrategy.Settings ? 'col-12 px-2' : ''
                }
                key={name}
                errorText={getErrors(node.messages)}
                className={
                  classname ??
                  `${getButtonStyleByStrategy} ${
                    location.pathname.includes(routes.settings)
                      ? 'float-end mt-3'
                      : 'mt-3 mb-3 w-100'
                  }`
                }
                type={type}
                name={name}
                hidden={!!hidden}
                value={value}
                disabled={disabled}
                formNoValidate={[Client.Username, Client.Identifier].includes(
                  node.attributes.name,
                )}
              >
                <i className={iconclassname} style={iconstyle} />
                {capitalizeFirstLetters(text)}
              </Button>
            );
          } else if (node.attributes.type === InputType.GROUPBUTTONS) {
            return (
              <GroupButtons
                wrapperClassname={node.wrapperClassname ?? ''}
                key={name}
                buttons={node.buttons}
              />
            );
          } else if (node.attributes.type === InputType.VERIFICATIONEMAIL) {
            return (
              <VerificationEmail
                name={name}
                key={name}
                onChange={onChangeOtpCode}
                isSms={value}
              />
            );
          } else {
            return (
              <Input
                wrapperClassname={`${getWrapperClassesByStrategy} mb-2`}
                error={getErrors(node.messages)}
                onChange={(e) => onChangeHandler(e, type)}
                value={values ? values[name] : ''}
                className="form-control"
                key={name}
                name={name}
                type={type}
                label={text}
                pattern={
                  name == Client.Username ? '^[a-z0-9_\\.@]{4,50}$' : undefined
                }
                title={
                  name == Client.Username
                    ? 'Username must be at least 4 characters long and alphabetic letters should be only lowercase. Can also contain numbers and special symbols: ".", "@"'
                    : undefined
                }
                autoCapitalize="none"
                disabled={disabled}
                required={required}
                icon={!!Maps.INPUT_ICON_MAP.get(name)}
                iconName={getIconNameByStrategy(flowStrategy, name)}
                tooltip={getTooltipByStrategy(flowStrategy, name)}
                tooltipMessage={Maps.TOOLTIP_MESSAGE_MAP.get(name)}
              />
            );
          }
        });
      },
      [flow, formState],
    );

    useEffect(() => {
      const values: { [key: string]: string } = {};
      flow?.ui?.nodes.forEach((node: NodeFlow) => {
        if (
          node.attributes &&
          ![InputType.BUTTON, InputType.DIVIDER, InputType.TABS].includes(
            node.attributes.type as InputType,
          )
        ) {
          values[node.attributes.name as string] =
            formState.values[node.attributes.name as string] &&
            !location.pathname.includes(routes.settings)
              ? formState.values[node.attributes.name as string]
              : (node.attributes.value as string);
        }
        if (
          [
            FlowStrategy.NotificationPreferencesEmail,
            FlowStrategy.NotificationPreferencesSms,
          ].includes(node.group as FlowStrategy) &&
          node?.attributes?.type === InputType.CHECKBOX &&
          location.pathname.includes(routes.settings)
        ) {
          values[node.attributes.name as string] =
            formState.values[node.attributes.name as string];
        }
      });

      setFormState((currentState) => ({
        ...currentState,
        values: values,
      }));
    }, [flow]);

    return (
      <form
        onSubmit={onSubmitHandler as FormEventHandler}
        action={flow?.ui?.action}
        method={flow?.ui?.method}
        hidden={!!hideFlow}
        data-testid={ConstantsTest.FORM}
      >
        {error?.message && <Error message={error.message} />}
        {showMessages && message && flowStrategy === activeStrategy && (
          <div className="py-3">
            <Message type={MessageType.Text} message={message} />
          </div>
        )}
        {flow.ui && renderFlow(flow)}
      </form>
    );
  },
);
